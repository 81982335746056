/* Global styles */
body, html {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  height: 100%;
}

/* Navbar */
.navbar {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  z-index: 1000; 
}

.navbar-nav .nav-link {
  font-size: 19px;
  font-weight: 600;
  color: #292727;
}

.custom-toggler {
  border: none;
  padding: 0.25rem 0.75rem;
  background: none;
  outline: none;
}

.custom-toggler-icon {
  display: inline-block;
  font-size: 24px;
  color: #333;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.custom-toggler:hover .custom-toggler-icon {
  transform: rotate(90deg);
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: none;
  box-shadow: none;
}

/* Footer */
footer {
  position: relative;
  background-color: #343a40;
  color: #f8f9fa;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.4); /* Add shadow to the top of the footer */
}

footer a {
  color: #f8f9fa;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

footer .container {
  max-width: 1200px;
}

/* Ensure images cover full height */
.container-fluid {
  height: 100vh;
}

.row {
  height: 100%;
}

/* Images */
.img-fluid {
  object-fit: cover; 
  height: 100%;
}

/* Hover effect */
.hover-expand {
  transition: transform 0.3s ease;
}

.hover-expand:hover {
  transform: scale(1.05);
}

.hover-expand-container {
  overflow: hidden;
}

/* Main image container */
.main-image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  z-index: 2;
  opacity: 0.8; 
  padding: 10px;

}

/* Text shadow */
.main-text-container h1 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* For smaller screens */
@media (max-width: 768px) {
  .navbar-nav .nav-link {
    font-size: 16px;
  }

  /* Hide smaller images, focus on main image */
  .img-fluid {
    display: none; 
  }

  .main-image-container {
    display: block;
    background-image: url("../public/img/DSC00003-1-1030x671-min.jpg"); /* Background image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    height: 100vh;
  }

  .main-image-container img {
    display: none; /* Hide the main image */
  }

  .main-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%; 
    padding: 10px;  }

}
